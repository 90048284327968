import React from 'react';
import './Aboutsection.css';
import { Link } from 'react-router-dom';
import himg1 from './images/himg1.png';
import himg2 from './images/himg2.png';
import himg3 from './images/himg3.png';
import himg4 from './images/himg4.png';

const Aboutsection = () => {
    const experiences = [
        {
            title: 'Hathnikund barrage boating experience',
            image: himg1,
        },
        {
            title: 'Hiking and Trekking',
            image: himg2,
        },
        {
            title: 'Jungle Safari',
            image: himg3,
        },
        {
            title: 'Cattle Feeding',
            image: himg4,
        },
    ];

    return (
        <div> 
            <section className="about-section  three-image">
                <div className="container" style={{ background: "antiquewhite" }}>
                    <br />
                    <div className="title-3">
                        <h2>About<span>Us</span></h2>
                    </div>
                    <div className="row ">
                        <div className="col-xl-8 about-column-upper">
                            <div className="about-text">
                                <p className="text-justify">
                                    When the chaos of life pulls you away,<br />
                                    And your heart longs for a quiet escape,<br />
                                    Pause. Breathe. Rediscover<br />
                                    Step away from the rush of the world,<br />
                                    And step into the embrace of stillness.<br />
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-4 about-column-lower">
                            <div className="about-text">
                                <p className="text-end">
                                    Awaken the essence of your spirit,<br />
                                    Follow the path that reconnects you to your core.<br />
                                    Know that a sanctuary awaits—<br />
                                    Where you can simply be.<br />
                                    Feel the earth beneath your feet,<br />
                                    And let nature heal, inspire, and ground you.<br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about_section about_section1">
                <div className="container">
                    <div className="row">
                        {experiences.map((experience, index) => (
                            <div key={index} className="col-lg-4 col-md-4 col-sm-6 col-6 mb-3">
                                <div className="about_img">
                                    <Link to="/Experiences">
                                        <img
                                            src={experience.image}
                                            className="img-fluid square-img"
                                            alt={experience.title}
                                        />
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Aboutsection;
