import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Banner from './Banner'
import Searchsection from './Searchsection'
import Aboutsection from './Aboutsection'
import Thirdsection from './Thirdsection'
import Gallerysection from './Gallerysection'
import Specialsection from './Specialsection'
import Videosection from './Videosection'
import Awesomeservice from './Awesomeservice'
import Subscribe from './Subscribe'
import Happycustomer from './Happycustomer'
// import { Helmet } from 'react-helmet-async';
const Home = () => {

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  return (
    <div>
       {/* <Helmet>                       
       <meta name="keywords" content="Farm Resort, Resort in Paonta Sahib, Farm Home Stay, Organic Farm Stay, Organic Farm Resort, Corporate Meeting Resort in Saharanpur, Natural Stay in Saharanpur, Corporate Meeting Hotels, Corporate Event Hotels Near Paonta Sahib, Corporate Event Resort near Paonta Sahib, eco-friendly stay, nature retreat, countryside vacation, Abhyaranyan Farmstay, sustainable tourism, farm vacation, rural retreat, vacation in nature, organic farmstay, farm experience" />  
             </Helmet>*/}
       <Header /> 
      <Banner />
      <Searchsection />
      <Aboutsection />
      <Thirdsection />
      <Gallerysection />
      <Specialsection />
      {/* <Videosection /> */}
      <Awesomeservice />
      {/* <Subscribe /> */}
      <Happycustomer />
      <Footer />

      <div className="tap-top" onClick={handleScrollTop} style={{ cursor: 'pointer' }}>
        <div>
          <i className="fas fa-angle-up"></i>
        </div>
      </div>

    </div>
  )
}

export default Home
